





















import Vue from "vue";
import {Component} from "vue-property-decorator";
import {RouteNames} from "@/router/types";
import {Getter} from "vuex-class";
import {StoreNamespace} from "@/store/types";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import {Timing} from "@/core/helpers/Timing";
import CircularLoading from "@/components/Loading/CircularLoading.vue";
import TournamentListCard from "@/components/Tournaments/TournamentListItem/TournamentListCard.vue";
import {Moment} from "moment";
import {VuexState} from "@/core/vuex/types";
import {AccountTournamentsModuleStoreModule} from "@/store";
import {AccountTournamentsModuleGetterTypes} from "@/store/modules/account/tournaments/types";
import Pagination from "@/components/Shared/Pagination.vue";
import {IPagination} from "@/core/service/types";
import {TournamentTypes} from "@/core/tournaments/types";

@Component({
  components: {Pagination, TournamentListCard, CircularLoading},
  enums: {
    RouteNames,
    VuexState
  }
})
export default class OwnedTournamentsPage extends Vue {
  @Getter(AccountTournamentsModuleGetterTypes.OWNED_TOURNAMENTS, { namespace: StoreNamespace.AccountTournaments })
  private readonly tournaments!: ITournamentEntity[]

  @Getter(AccountTournamentsModuleGetterTypes.OWNED_TOURNAMENTS_VUEX_STATE, { namespace: StoreNamespace.AccountTournaments })
  private readonly currentState!: VuexState

  @Getter(AccountTournamentsModuleGetterTypes.OWNED_TOURNAMENTS_PAGINATION, { namespace: StoreNamespace.AccountTournaments })
  private readonly pagination!: IPagination

  private interval: NodeJS.Timeout | null = null
  private dateTimeNow: Moment = Timing.getTimeNow()

  private async created () {
    await AccountTournamentsModuleStoreModule.initializeOwnedTournaments()
    this.startTimer()
  }

  private startTimer () {
    this.interval = setInterval(() => {
      this.dateTimeNow = Timing.getTimeNow()
    }, 1000)
  }

  private stopTimer () {
    if (this.interval != null) {
      clearInterval(this.interval)
      this.interval = null
    }
  }

  private beforeDestroy () {
    this.stopTimer()
    AccountTournamentsModuleStoreModule.clearOwnedTournaments()
  }

  private onCurrentPageChange (newCurrentPage: number) {
    AccountTournamentsModuleStoreModule.onCurrentPaginationPageChange({
      dataType: TournamentTypes.Owned,
      newCurrentPage: newCurrentPage
    })
  }
}
